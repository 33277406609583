<template>
  <div class="form_wrapper">
    <div class="form_container">
      <div class="title_container">
        <h2>
          {{
            lang == "uz"
              ? "Bemorni ro'yxatdan o'tkazish"
              : "Регистрация пациентов"
          }}
        </h2>
      </div>
      <div class="row clearfix">
        <div class="">
          <form>
            <div class="input_header">
              {{ lang == "uz" ? "Ismi :" : "Имя :" }}
            </div>
            <div class="input_field">
              <span><a-icon type="user" theme="outlined" /></span>
              <input
                class="inputField"
                type="text"
                name="ismi"
                :placeholder="lang == 'uz' ? 'Ismi' : 'Имя'"
                v-model="first_name"
                required
              />
            </div>
            <div class="input_header">
              {{ lang == "uz" ? "Familiyasi :" : "Фамилия :" }}
            </div>
            <div class="input_field">
              <span><a-icon type="user" theme="outlined" /></span>
              <input
                class="inputField"
                type="text"
                name="familiyasi"
                :placeholder="lang == 'uz' ? 'Familiyasi' : 'Фамилия'"
                v-model="last_name"
                required
              />
            </div>
            <div class="input_header">
              {{ lang == "uz" ? "Sharifi :" : "Отчество :" }}
            </div>
            <div class="input_field">
              <span><a-icon type="user" theme="outlined" /></span>
              <input
                class="inputField"
                type="text"
                name="sharifi"
                :placeholder="lang == 'uz' ? 'Sharifi' : 'Отчество'"
                v-model="middle_name"
                required
              />
            </div>
            <div class="input_header">
              {{ lang == "uz" ? "Jinsi :" : "Пол :" }}
            </div>
            <div class="input_field select_option">
              <select v-model="gender">
                <option value="null">
                  {{ lang == "uz" ? "Jinsini tanlang" : "Выберите пол" }}
                </option>
                <option value="1">
                  {{ lang == "uz" ? "Erkak" : "Мужчина" }}
                </option>
                <option value="2">
                  {{ lang == "uz" ? "Ayol" : "Женщина" }}
                </option>
              </select>
              <div class="select_arrow"></div>
            </div>
            <div class="input_header">
              {{ lang == "uz" ? "Tug'ilgan sanasi :" : "Дата рождения:" }}
            </div>
            <div class="input_field">
              <span><a-icon type="calendar" theme="outlined" /></span>
              <input
                class="inputField"
                type="date"
                name="sana"
                v-model="birth_date"
                required
              />
            </div>
            <div class="input_header">
              {{ lang == "uz" ? "PINFL :" : "ПИНФЛ" }}
            </div>
            <div class="input_field">
              <span><a-icon type="database" theme="outlined" /></span>
              <input
                class="inputField"
                type="text"
                name="pinfl"
                maxlength="14"
                @input="isNumber()"
                :placeholder="lang == 'uz' ? 'PINFL' : 'ПИНФЛ'"
                v-model="pinfl"
                required
              />
            </div>
            <div class="input_header">
              {{ lang == "uz" ? "Telefon raqami :" : "Номер телефона :" }}
            </div>
            <div class="input_field">
              <span><a-icon type="phone" theme="outlined" /></span>
              <input
                class="inputField"
                type="text"
                name="telefon"
                :placeholder="
                  lang == 'uz' ? 'Telefon raqami' : 'Номер телефона'
                "
                maxlength="13"
                v-model="phone_number"
                required
              />
            </div>
            <div class="input_header">
              {{ lang == "uz" ? "Manzili :" : "Адрес :" }}
            </div>
            <div class="input_field">
              <span><a-icon type="home" theme="outlined" /></span>
              <input
                class="inputField"
                type="text"
                name="manzili"
                :placeholder="lang == 'uz' ? 'Manzili' : 'Адрес'"
                v-model="address"
                required
              />
            </div>
            <!-- <input
              class="button"
              type="submit"
              value="Saqlash"
              @click="goToMain()"
            /> -->
          </form>
          <button class="add_btn" @click="goToMain()">
            {{ lang == "uz" ? "Saqlash" : "Сохранять" }}
          </button>
        </div>
      </div>
      <a-modal v-model="analysisShow" @ok="handleOk">
        <p class="text-primary">
          {{
            lang == "uz"
              ? "Ushbu bemor oldin ro'yxatdan o'tkazilgan. Kasallik varaqasi ochishni xohlaysizmi ?"
              : "Этот пациент был ранее зарегистрирован. Хотите открыть больничный?"
          }}
        </p>
      </a-modal>
    </div>
  </div>
</template>

<script>
import router from "@/router/index";
import axios from "@/axios.js";
export default {
  data() {
    return {
      first_name: "",
      last_name: "",
      middle_name: "",
      birth_date: null,
      address: "",
      phone_number: "+998",
      gender: null,
      pinfl: "",
      analysisShow: false,
      lang: null,
    };
  },
  mounted() {
    if (localStorage.getItem("lang")) {
      this.lang = localStorage.getItem("lang");
    } else {
      localStorage.setItem("lang", "uz");
    }
  },
  methods: {
    isNumber() {
      this.pinfl = this.pinfl
        .replace(/[^0-9.]/g, "")
        .replace(/(\..*)\./g, "$1");
    },
    setGender(val) {
      this.gender = val;
    },
    handleOk() {
      let patient_id = null;
      axios.get("/api/patients").then((res) => {
        let person = res.data._embedded.patients.filter(
          (item) => Number(item.pnfl) == Number(this.pinfl)
        );
        let patient_url = person[0]._links.self.href;
        let patient_id = patient_url.substring(
          patient_url.lastIndexOf("/") + 1,
          patient_url.length
        );
        axios
          .post("/api/analysis", {
            patientId: patient_id,
          })
          .then(() => {
            this.analysisShow = false;
            router.replace("/patients");
            this.$toast.success(
              this.lang == "uz"
                ? "Bemorga kasallik varaqasi ochildi !"
                : "Больному открыта больничная карта!",
              {
                position: "top-right",
                timeout: 5000,
                draggablePercent: 0.6,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
              }
            );
          })
          .catch((err) => {
            this.analysisShow = false;
            console.log(err);
            this.$toast.error(
              this.lang == "uz"
                ? "Ma'lumotlarni yuklashda xatolik yuz berdi !"
                : "Произошла ошибка при загрузке данных!",
              {
                position: "top-right",
                timeout: 5000,
                draggablePercent: 0.6,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
              }
            );
          });
      });
    },
    goToMain() {
      if (
        this.first_name.length > 3 &&
        this.last_name.length > 3 &&
        this.birth_date &&
        this.address.length > 3 &&
        this.gender &&
        this.phone_number.length > 12 &&
        this.pinfl.length == 14
      ) {
        axios
          .post("/api/patients", {
            registrationId: Number(localStorage.getItem("role_id")) || 1,
            firstName: this.first_name,
            lastName: this.last_name,
            middleName: this.middle_name,
            birthDate: this.birth_date,
            address: this.address,
            phoneNumber: this.phone_number,
            gender: this.gender,
            pnfl: Number(this.pinfl),
          })
          .then((res) => {
            let patient_id = res.data._links.self.href.substring(
              res.data._links.self.href.lastIndexOf("/") + 1
            );
            console.log(res, patient_id);
            axios
              .post("/api/analysis", {
                patientId: patient_id,
              })
              .then(() => {
                router.replace("/patients");
                this.$toast.success(
                  this.lang == "uz"
                    ? "Bemor qo'shildi !"
                    : "Пациент добавлено !",
                  {
                    position: "top-right",
                    timeout: 5000,
                    draggablePercent: 0.6,
                    hideProgressBar: true,
                    closeButton: "button",
                    icon: true,
                  }
                );
              })
              .catch((err) => {
                console.log(err);
                this.$toast.error(
                  this.lang == "uz"
                    ? "Ma'lumotlarni yuklashda xatolik yuz berdi !"
                    : "Произошла ошибка при загрузке данных!",
                  {
                    position: "top-right",
                    timeout: 5000,
                    draggablePercent: 0.6,
                    hideProgressBar: true,
                    closeButton: "button",
                    icon: true,
                  }
                );
              });
          })
          .catch(() => {
            this.analysisShow = true;
            this.$toast.error(
              lang == "uz"
                ? "Ushbu bemor oldin ro'yxatdan o'tkazilgan !"
                : "Этот пациент уже зарегистрирован!",
              {
                position: "top-right",
                timeout: 5000,
                draggablePercent: 0.6,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
              }
            );
          });
        // });
      } else {
        this.$toast.error(
          this.lang == "uz"
            ? "Maydonlarni to'g'ri to'ldiring !"
            : "Заполняйте поля правильно!",
          {
            position: "top-right",
            timeout: 5000,
            draggablePercent: 0.6,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
          }
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$yellow: #1890ff;
$black: #000000;
$grey: #1890ff;

.input_header {
  font-weight: 700;
  margin-top: 15px;
  margin-bottom: 5px;
}
.add_btn {
  background: $yellow;
  height: 35px;
  line-height: 35px;
  border-radius: 5px;
  width: 100%;
  border: none;
  outline: none;
  cursor: pointer;
  color: #fff;
  font-size: 1.1em;
  margin-bottom: 10px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  &:hover {
    background: darken($yellow, 7%);
  }
  &:focus {
    background: darken($yellow, 7%);
  }
}
.inputField {
  display: block;
  width: 100%;
  height: 36px;
  padding: 0.375rem 0.75rem;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.5;
  color: black;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  outline: none;
  font-family: "Montserrat";
}
.clearfix {
  &:after {
    content: "";
    display: block;
    clear: both;
    visibility: hidden;
    height: 0;
  }
}
.form_wrapper {
  background: #fff;
  width: 450px;
  max-width: 100%;
  box-sizing: border-box;
  padding: 25px;
  margin: 3% auto 0;
  position: relative;
  z-index: 1;
  border-top: 5px solid $yellow;
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  -webkit-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  -webkit-transition: none;
  transition: none;
  -webkit-animation: expand 0.8s 0.6s ease-out forwards;
  animation: expand 0.8s 0.6s ease-out forwards;
  opacity: 0;
  h2 {
    font-size: 1.5em;
    line-height: 1.5em;
    margin: 0;
  }
  .title_container {
    text-align: center;
    padding-bottom: 15px;
  }
  h3 {
    font-size: 1.1em;
    font-weight: normal;
    line-height: 1.5em;
    margin: 0;
  }
  label {
    font-size: 12px;
  }
  .row {
    margin: 10px -15px;
    > div {
      padding: 0 15px;
      box-sizing: border-box;
    }
  }
  .col_half {
    width: 50%;
    float: left;
  }
  .input_field {
    position: relative;
    margin-bottom: 20px;
    -webkit-animation: bounce 0.6s ease-out;
    animation: bounce 0.6s ease-out;
    > span {
      position: absolute;
      left: 0;
      top: 0;
      color: #333;
      height: 100%;
      border-right: 1px solid $grey;
      text-align: center;
      width: 30px;
      > i {
        padding-top: 10px;
      }
    }
  }
  .textarea_field {
    > span {
      > i {
        padding-top: 10px;
      }
    }
  }
  input {
    &[type="text"],
    &[type="date"],
    &[type="email"],
    &[type="password"] {
      width: 100%;
      padding: 8px 10px 9px 35px;
      height: 35px;
      border: 1px solid $grey;
      box-sizing: border-box;
      outline: none;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
    }
    &[type="text"]:hover,
    &[type="email"]:hover,
    &[type="password"]:hover {
      background: #fafafa;
    }
    &[type="text"]:focus,
    &[type="email"]:focus,
    &[type="date"]:focus {
      -webkit-box-shadow: 0 0 2px 1px #1890ff;
      -moz-box-shadow: 0 0 2px 1px #1890ff;
      box-shadow: 0 0 2px 1px #1890ff;
      border: 1px solid $yellow;
      background: #fafafa;
    }
    &[type="submit"] {
      background: $yellow;
      height: 35px;
      line-height: 35px;
      border-radius: 5px;
      width: 100%;
      border: none;
      outline: none;
      cursor: pointer;
      color: #fff;
      font-size: 1.1em;
      margin-bottom: 10px;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      &:hover {
        background: darken($yellow, 7%);
      }
      &:focus {
        background: darken($yellow, 7%);
      }
    }
    &[type="checkbox"],
    &[type="radio"] {
      border: 0;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }
  }
}
.form_container {
  .row {
    .col_half.last {
      border-left: 1px solid $grey;
    }
  }
}
.checkbox_option {
  label {
    margin-right: 1em;
    position: relative;
    &:before {
      content: "";
      display: inline-block;
      width: 0.5em;
      height: 0.5em;
      margin-right: 0.5em;
      vertical-align: -2px;
      border: 2px solid $grey;
      padding: 0.12em;
      background-color: transparent;
      background-clip: content-box;
      transition: all 0.2s ease;
    }
    &:after {
      border-right: 2px solid black;
      border-top: 2px solid black;
      content: "";
      height: 20px;
      left: 2px;
      position: absolute;
      top: 7px;
      transform: scaleX(-1) rotate(135deg);
      transform-origin: left top;
      width: 7px;
      display: none;
    }
  }
  input {
    &:hover + label:before {
      border-color: black;
    }
    &:checked + label {
      &:before {
        border-color: black;
      }
      &:after {
        -moz-animation: check 0.8s ease 0s running;
        -webkit-animation: check 0.8s ease 0s running;
        animation: check 0.8s ease 0s running;
        display: block;
        width: 7px;
        height: 20px;
        border-color: black;
      }
    }
  }
}
.radio_option {
  label {
    margin-right: 1em;
    &:before {
      content: "";
      display: inline-block;
      width: 0.5em;
      height: 0.5em;
      margin-right: 0.5em;
      border-radius: 100%;
      vertical-align: -3px;
      border: 2px solid $grey;
      padding: 0.15em;
      background-color: transparent;
      background-clip: content-box;
      transition: all 0.2s ease;
    }
  }
  input {
    &:hover + label:before {
      border-color: black;
    }
    &:checked + label:before {
      background-color: black;
      border-color: black;
    }
  }
}
.select_option {
  position: relative;
  width: 100%;
  select {
    display: inline-block;
    width: 100%;
    height: 35px;
    padding: 0px 15px;
    cursor: pointer;
    color: black;
    border: 1px solid $grey;
    border-radius: 0;
    background: #fff;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    transition: all 0.2s ease;
    &::-ms-expand {
      display: none;
    }
    &:hover,
    &:focus {
      color: black;
      background: #fafafa;
      border-color: black;
      outline: none;
    }
  }
}
.select_arrow {
  position: absolute;
  top: calc(50% - 4px);
  right: 15px;
  width: 0;
  height: 0;
  pointer-events: none;
  border-width: 8px 5px 0 5px;
  border-style: solid;
  border-color: #7b7b7b transparent transparent transparent;
}

.select_option select {
  &:hover + .select_arrow,
  &:focus + .select_arrow {
    border-top-color: black;
  }
}
.credit {
  position: relative;
  z-index: 1;
  text-align: center;
  padding: 15px;
  color: $yellow;
  a {
    color: darken($yellow, 7%);
  }
}
@-webkit-keyframes check {
  0% {
    height: 0;
    width: 0;
  }
  25% {
    height: 0;
    width: 7px;
  }
  50% {
    height: 20px;
    width: 7px;
  }
}

@keyframes check {
  0% {
    height: 0;
    width: 0;
  }
  25% {
    height: 0;
    width: 7px;
  }
  50% {
    height: 20px;
    width: 7px;
  }
}

@-webkit-keyframes expand {
  0% {
    -webkit-transform: scale3d(1, 0, 1);
    opacity: 0;
  }
  25% {
    -webkit-transform: scale3d(1, 1.2, 1);
  }
  50% {
    -webkit-transform: scale3d(1, 0.85, 1);
  }
  75% {
    -webkit-transform: scale3d(1, 1.05, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    opacity: 1;
  }
}

@keyframes expand {
  0% {
    -webkit-transform: scale3d(1, 0, 1);
    transform: scale3d(1, 0, 1);
    opacity: 0;
  }
  25% {
    -webkit-transform: scale3d(1, 1.2, 1);
    transform: scale3d(1, 1.2, 1);
  }
  50% {
    -webkit-transform: scale3d(1, 0.85, 1);
    transform: scale3d(1, 0.85, 1);
  }
  75% {
    -webkit-transform: scale3d(1, 1.05, 1);
    transform: scale3d(1, 1.05, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }
}

@-webkit-keyframes bounce {
  0% {
    -webkit-transform: translate3d(0, -25px, 0);
    opacity: 0;
  }
  25% {
    -webkit-transform: translate3d(0, 10px, 0);
  }
  50% {
    -webkit-transform: translate3d(0, -6px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, 2px, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes bounce {
  0% {
    -webkit-transform: translate3d(0, -25px, 0);
    transform: translate3d(0, -25px, 0);
    opacity: 0;
  }
  25% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }
  50% {
    -webkit-transform: translate3d(0, -6px, 0);
    transform: translate3d(0, -6px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, 2px, 0);
    transform: translate3d(0, 2px, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@media (max-width: 600px) {
  .form_wrapper {
    .col_half {
      width: 100%;
      float: none;
    }
  }
  .bottom_row {
    .col_half {
      width: 50%;
      float: left;
    }
  }
  .form_container {
    .row {
      .col_half.last {
        border-left: none;
      }
    }
  }
  .remember_me {
    padding-bottom: 20px;
  }
}
</style>